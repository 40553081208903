import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { RadioSVGMap, SVGMap } from 'react-svg-map';
import Santiago from '../../../lib/santiago-map';
import utils from '../../../helpers/utils';
import PropTypes from 'prop-types';
import Loading from '../UI/Loading';
import Error from '../UI/Error';
import commonConstants from '../../../constants/commonConstants';
import Chip from '../../../../node_modules/@material-ui/core/Chip/Chip';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '../../../../node_modules/@material-ui/core/IconButton/IconButton';
import Dialog from '../../../../node_modules/@material-ui/core/Dialog/Dialog';
import comunasRm from '../../../constants/comunasRm';
import Button from '../../../../node_modules/@material-ui/core/Button/Button';

const styles = theme => ({
  mapColorLow: {
    backgroundColor: '#00a84d',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  mapColorMedium: {
    backgroundColor: '#d99527',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  mapColorHigh: {
    backgroundColor: '#d9650c',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  mapColorVeryHigh: {
    backgroundColor: '#c3211a',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  mapColorPandemic: {
    backgroundColor: '#9b18bf',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  chip: {
    margin: theme.spacing.unit,
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing.unit * 2}px`,
  },
});

class Map extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      pointedLocation: null,
      tooltipStyle: {
        display: 'none'
      },
      locationDialogOpen: false,
      dialogComunaName: "Prueba",
      dialogWeek: "22p",
      dialogLocationClassName: "",
      dialogIndiceRiestoName: "Alto prueba",
    };

    this.handleLocationMouseOver = this.handleLocationMouseOver.bind(this);
    this.handleLocationMouseOut = this.handleLocationMouseOut.bind(this);
    this.handleLocationMouseMove = this.handleLocationMouseMove.bind(this);
    this.handleOpenLocationDialog = this.handleOpenLocationDialog.bind(this);
    this.handleCloseLocationDialog = this.handleCloseLocationDialog.bind(this);
  }

  static propTypes = {
    error: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    mapData: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    error: null,
  };

  handleLocationMouseOver(event) {
    if(window.innerWidth < 700){
      return;
    }
    event.persist();
    const comunaId = event.target.attributes.id.value;
    const location = {id: comunaId};
    const { mapData } = this.props;
    const comunaName = mapData[comunaId].name;

    const pointedLocation = (
      <React.Fragment>
      {/*<IconButton onClick={this.handleLocationMouseOut} style={{position: 'absolute', top: '0px', right: '0px'}} aria-label="Cerrar">*/}
        {/*<CloseIcon />*/}
      {/*</IconButton>*/}
      <h5>{comunaName}</h5>
      <p style={{fontSize: '12px'}}>Semana Epidemiológica {mapData.week}</p>
      <p>Indice de riesgo: <strong className={this.getLocationClassName(location)}>{this.getIndiceRiesgo(comunaId)}</strong></p>
    </React.Fragment>);

    const tooltipStyle = {
      display: 'block',
      top: event.clientY + 10,
      left: event.clientX - 100
    };
    this.setState({ pointedLocation, tooltipStyle });
  }

  handleLocationMouseOut() {
    if(window.innerWidth < 700){
      return;
    }
    this.setState({ pointedLocation: null, tooltipStyle: { display: 'none' } });
  }

  handleLocationMouseMove(event) {
    if(window.innerWidth < 700){
      return;
    }
    const tooltipStyle = {
      display: 'block',
      top: event.clientY + 10,
      left: event.clientX - 100
    };
    this.setState({ tooltipStyle });
  }

  getLocationClassName(location) {
    const { mapData } = this.props;
    return mapData[location.id].heatMapClassName;
  }

  getIndiceRiesgo(comunaId){
    const { mapData } = this.props;
    return mapData[comunaId].heatMapName;
  }

  handleOpenLocationDialog(event){
    event.persist();
    const comunaId = event.target.attributes.id.value;
    const location = {id: comunaId};
    const { mapData } = this.props;
    const comunaName = mapData[comunaId].name;

    this.setState({
      dialogComunaName: comunaName,
      dialogWeek: mapData.week,
      dialogLocationClassName: this.getLocationClassName(location),
      dialogIndiceRiestoName: this.getIndiceRiesgo(comunaId),
      locationDialogOpen: true
    });
    this.setState({ locationDialogOpen: true});
  }
  handleCloseLocationDialog(){
    this.setState({ locationDialogOpen: false});
  }

  render() {
    const { classes, mapData, loading, error } = this.props;
    const {locationDialogOpen, dialogComunaName,
      dialogWeek, dialogLocationClassName,
      dialogIndiceRiestoName} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error content={error}/>;
    if(!mapData) return <h4>No se encontraron datos.</h4>

    return (
      <Paper className="paper" style={{height: '100%'}}>
        <h4>Región Metropolitana</h4>
        <hr/>
        <h6>Semana Epidemiológica {mapData.week}</h6>
        <p className={classes.dividerFullWidth} style={{ fontSize: '12px', marginTop: '-5px', marginBotom: '5px'}} color="textSecondary">
          {commonConstants.getDatesFromWeek(mapData.week)}
        </p>


        <Paper className="paper">
          <SVGMap map={Santiago}
                       locationClassName={(location) => {
                         return mapData[location.id].heatMapClassName;
                       }}
                       onLocationClick={this.handleOpenLocationDialog}
                       onLocationMouseOver={this.handleLocationMouseOver}
                       onLocationMouseOut={this.handleLocationMouseOut}
                       onLocationMouseMove={this.handleLocationMouseMove}
          />
          <div className="map_tooltip" style={this.state.tooltipStyle}>
            {this.state.pointedLocation}
          </div>
        </Paper>
        <Grid container spacing={16} direction="row" justify="center" alignItems="center" style={{marginTop: '10px'}}>
          <Grid item>
            <strong style={{backgroundColor: "#00a84d", color: "white", padding: 5}}>
              Bajo
            </strong>
          </Grid>
          <Grid item>
            <strong style={{backgroundColor: "#d9ce30", color: "white", padding: 5}}>
              Medio
            </strong>
          </Grid>
          <Grid item>
            <strong style={{backgroundColor: "#d9650c", color: "white", padding: 5}}>
              Alto
            </strong>
          </Grid>
          <Grid item>
            <strong style={{backgroundColor: "#c3211a", color: "white", padding: 5}}>
              Muy Alto
            </strong>
          </Grid>
          <Grid item>
            <strong style={{backgroundColor: "#9b18bf", color: "white", padding: 5}}>
              Brote
            </strong>
          </Grid>
          <Grid item>
            <strong style={{backgroundColor: "#868686", color: "white", padding: 5}}>
              Sin datos
            </strong>
          </Grid>

        </Grid>

        <Dialog open={locationDialogOpen}
                onClose={this.handleCloseLocationDialog}
                aria-labelledby="simple-dialog-title">
          <Button variant="contained"
                  color="secondary"
                  size="large"
                  onClick={this.handleCloseLocationDialog}
                  style={{position: 'fixed', top: '1px', right: '1px'}}>
            <CloseIcon style={{marginRight: 1}} />
            Cerrar
          </Button>
          <div style={{margin: 15}}>
            <h5>{dialogComunaName}</h5>
            <p style={{fontSize: '14px'}}>Semana Epidemiológica {dialogWeek}</p>
            <p>{"Indice de riesgo: "}
              <strong className={dialogLocationClassName}>
                {dialogIndiceRiestoName}
              </strong>
            </p>
          </div>
        </Dialog>

      </Paper>
    );
  }

}

export default withStyles(styles)(Map);
