import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Templates
import TemplateNothing from '../components/Templates/Nothing';
import TemplateSidebar from '../components/Templates/Sidebar';

// Routes
import Home from '../components/Home';
import SignUpContainer from '../../containers/SignUp';
import SignUpComponent from '../components/User/SignUp';
import LoginContainer from '../../containers/Login';
import LoginComponent from '../components/User/Login';
import UpdateProfileContainer from '../../containers/UpdateProfile';
import UpdateProfileComponent from '../components/User/UpdateProfile';
import Error from '../components/UI/Error';
import TermsOfService from '../components/TermsOfService';
import Privacy from '../components/Privacy';
import Cookies from '../components/Cookies';

const Index = () => (
  <Switch>
    <Route
      exact
      path="/"
      render={props => (
        <TemplateSidebar>
          <Home {...props} />
        </TemplateSidebar>
      )}
    />
    {/*<Route*/}
      {/*exact*/}
      {/*path="/about"*/}
      {/*render={props => (*/}
        {/*<TemplateSidebar pageTitle="Quienes Somos">*/}
          {/*<About {...props} />*/}
        {/*</TemplateSidebar>*/}
      {/*)}*/}
    {/*/>*/}
    <Route
      path="/sign-up"
      render={props => (
        <TemplateNothing pageTitle="Sign Up">
          <SignUpContainer {...props} Layout={SignUpComponent} />
        </TemplateNothing>
      )}
    />
    <Route
      path="/login"
      render={props => (
        <TemplateNothing pageTitle="Login">
          <LoginContainer {...props} Layout={LoginComponent} />
        </TemplateNothing>
      )}
    />
    {/*<Route*/}
      {/*path="/forgot-password"*/}
      {/*render={props => (*/}
        {/*<TemplateNothing pageTitle="Forgot Password">*/}
          {/*<ForgotPasswordContainer {...props} Layout={ForgotPasswordComponent} />*/}
        {/*</TemplateNothing>*/}
      {/*)}*/}
    {/*/>*/}
    <Route
      path="/update-profile"
      render={props => (
        <TemplateSidebar pageTitle="Update Profile">
          <UpdateProfileContainer {...props} Layout={UpdateProfileComponent} />
        </TemplateSidebar>
      )}
    />



    <Route
      path="/terminos-de-uso"
      render={props => (
        <TemplateSidebar pageTitle="Terminos de Uso">
          <TermsOfService {...props} />
        </TemplateSidebar>
      )}
    />

    <Route
      path="/politica-de-privacidad"
      render={props => (
        <TemplateSidebar pageTitle="Política de Privacidad">
          <Privacy {...props} />
        </TemplateSidebar>
      )}
    />

    <Route
      path="/cookies"
      render={props => (
        <TemplateSidebar pageTitle="Terminos de Uso">
          <Cookies {...props} />
        </TemplateSidebar>
      )}
    />


    {/*<Route*/}
      {/*path="/recipes"*/}
      {/*render={props => (*/}
        {/*<TemplateSidebar pageTitle="Recipes">*/}
          {/*<Grid container spacing={16}>*/}
            {/*<Grid item xs={12}>*/}
              {/*<Paper className="paper">*/}
                {/*<Grid container spacing={16}>*/}
                  {/*<Grid item xs={6}>*/}
                    {/*<ChartContainer {...props} Layout={ChartTypes}/>*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={6} >*/}
                    {/*<ChartYearCompareSelect />*/}
                  {/*</Grid>*/}
                {/*</Grid>*/}
                {/*<ChartContainer {...props} Layout={Chart}/>*/}
              {/*</Paper>*/}
            {/*</Grid>*/}
          {/*</Grid>*/}
          {/*/!*<RecipesContainer {...props} Layout={RecipeListingComponent} />*!/*/}
        {/*</TemplateSidebar>*/}
      {/*)}*/}
    {/*/>*/}
    {/*<Route*/}
      {/*path="/recipe/:id"*/}
      {/*render={props => (*/}
        {/*<TemplateSidebar pageTitle="Recipe View">*/}
          {/*<RecipesContainer {...props} Layout={RecipeSingleComponent} />*/}
        {/*</TemplateSidebar>*/}
      {/*)}*/}
    {/*/>*/}
    <Route
      render={props => (
        <TemplateSidebar pageTitle="404 - Página no encontrada">
          <Error {...props} title="404" content="Disculpa, esta página no existe. ¿Quizás sea una ilusión? No tranquilo, sólo es un error :)" />
        </TemplateSidebar>
      )}
    />
  </Switch>
);

export default Index;
