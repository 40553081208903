import Store from '../store/default';
import commonConstants from '../constants/commonConstants';
import comunasRm from '../constants/comunasRm';
import utils from '../helpers/utils';

export const initialState = Store;

// const createVirusOtrosProperty = (chartDataElement, filters) => {
//   chartDataElement.otros = chartDataElement.tot_resp -
//     (chartDataElement.VRS +
//       chartDataElement.Ad +
//       chartDataElement.Para +
//       chartDataElement.Inf_A +
//       chartDataElement.Inf_B +
//       chartDataElement.Meta
//     );
// };
//
// const createOtrosProperty = (chartDataElement, filters) => {
//   chartDataElement.otros = chartDataElement.tot_resp -
//     (chartDataElement.IRA_Alta +
//       chartDataElement.Bronquitis +
//       chartDataElement.Neumonia +
//       chartDataElement.Tipo_Influenza);
// };
//
// const addForecastProperties = (chartDataArray, filters, i) => {
//   let currentYear = new Date().getFullYear().toString();
//   if(filters.yearComparatorName.length > 0 && filters.yearComparatorName.includes(currentYear)){
//     if( chartDataArray[i].hasOwnProperty(currentYear) ){
//       console.log("TIENE MISMO AÑO ACTUAL!");
//       chartDataArray[i].tot_resp = chartDataArray[i][currentYear];
//       delete chartDataArray[i][currentYear];
//     }
//   }
//   if(chartDataArray[i].hasOwnProperty('forecast') && chartDataArray[i].forecast){
//     if(i !== 0){
//       chartDataArray[i-1].forecastValue = chartDataArray[i-1].tot_resp;
//     }
//     chartDataArray[i].forecastValue = chartDataArray[i].tot_resp;
//     delete chartDataArray[i].tot_resp;
//   }
// };
//
// const processChartData = (chartData, filters) => {
//   for (let i=0; i < chartData.length; i++){
//     if(filters.yearComparatorName.length === 0){
//       if(filters.infeccionesDataCategory === commonConstants.INFECCIONES_DATA_CATEGORY.POR_CUADRO_RESPIRATORIO){
//         createOtrosProperty(chartData[i], filters);
//       }
//       if(filters.infeccionesDataCategory === commonConstants.CHART_TYPES.BY_VIRUS_RESPIRATORIOS){
//         createVirusOtrosProperty(chartData[i], filters);
//       }
//     }
//     addForecastProperties(chartData, filters, i);
//   }
// };



export default function recipeReducer(state = initialState, action) {
  switch (action.type) {
    case 'MAP_DATA': {
      // console.log("reducers/map.js -> MAP_DATA -> action.heatMapData: ", action.heatMapData);
      let mapData = comunasRm;
      if( action.hasOwnProperty('heatMapData') ) {
        let comuna;
        for(var comunaId in mapData){
          if(comunaId === 'week')
            continue;
          comuna = mapData[comunaId];
          // console.log("comunaId: ", comunaId);
          // console.log("comuna.id: ", comuna.id);
          comuna.heatMapId = action.heatMapData[comuna.id];
          if(!comuna.heatMapId) {
            comuna.heatMapId = "no-data";
          }
          comuna.heatMapClassName = commonConstants.HEAT_MAP_MAPPING[comuna.heatMapId].className;
          comuna.heatMapName = commonConstants.HEAT_MAP_MAPPING[comuna.heatMapId].name;

        }
        mapData.week = action.heatMapData.week;
      }
      // console.log("reducers/map.js -> MAP_DATA -> returning mapData: ", mapData);
      return {
        ...state,
        mapData,
      };
    }

    // case 'CHART_LOCAL_DATA': {
    //   console.log("reducers/chart.js -> CHART_LOCAL_DATA -> state.chartData", state.chartData);
    //   let chartLocalData = [];
    //   if(filters.virus === commonConstants.VIRUS.TOT_RESP) {
    //     chartLocalData = state.chartData;
    //   }else{
    //     console.log("reducers/chart.js -> CHART_LOCAL_DATA -> cleaning data with VIRUS_TYPE -> ", filters.virus);
    //     try{
    //       state.chartData.forEach((item, index) => {
    //         chartLocalData.push({
    //           tot_resp: item[filters.virus],
    //           week: item.week,
    //         });
    //         if( item.hasOwnProperty('forecast') && item.forecast ){
    //           chartLocalData[index].forecast = item.forecast;
    //         }
    //         addForecastProperties(chartLocalData, filters, index);
    //       });
    //     }catch(e){
    //       console.log("error cleaning data: ", e);
    //     }
    //     console.log("reducers/chart.js -> CHART_LOCAL_DATA -> NO VIRUS.TOT_RESP -> AFTER CLEANING chartLocalData: ", chartLocalData);
    //     console.log("reducers/chart.js -> CHART_LOCAL_DATA -> NO VIRUS.TOT_RESP -> AFTER CLEANING state.chartData: ", state.chartData);
    //   }
    //
    //   return {
    //     ...state,
    //     chartLocalData,
    //     filters: action.filters,
    //     useLocalData: true
    //   };
    // }

    default:
      return state;
  }
}
