import React from 'react';
import { Row, Col } from 'reactstrap';
import Grid from '../../../../node_modules/@material-ui/core/Grid/Grid';
import GridList from '../../../../node_modules/@material-ui/core/GridList/GridList';
import GridListTile from '../../../../node_modules/@material-ui/core/GridListTile/GridListTile';
import logoAirData from '../../../images/logos/logo-airdata.png';
import logoAria from '../../../images/logos/logo-aria.png';
import logoCams from '../../../images/logos/logo-cams.png';
import logoFundamas from '../../../images/logos/logo-fundamas.png';
import logoZeke from '../../../images/logos/logo-zeke.png';

const Footer = () => (
    <footer className="mt-5">
      <Grid className="client-logo-container"
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={40}
            style={{marginTop: '20px'}}>
        <Grid item>
          <a href="http://www.aria.fr" target='_blank'>
            <img width={150} src={logoAria} alt="logoAria"/>
          </a>
        </Grid>
      </Grid>
      <br/>

      <Row>
        <Col sm="12" className="text-right pt-3">

          <p>
            {' '}
            <a href="/terminos-de-uso">
              Términos de uso
            </a>
            {' '}
            &nbsp; | &nbsp;
            {' '}
            <a href="/politica-de-privacidad">
              Política de Privacidad
            </a>
            {' '}
            &nbsp; | &nbsp;
            {' '}
            <a href="cookies">
              Política de Cookies
            </a>

          </p>
        </Col>
      </Row>



      <Row>
        <Col sm="12" className="text-right pt-3">

          <p>ARIA Technologies S.A., 8-10 rue de la ferme, 92100 Boulogne-Billancourt, France</p>

          <p>
            © {new Date().getFullYear()}
            {' '}
            <a target="_blank" rel="noopener noreferrer" href="/">
              Aire Salud
            </a>
            {' '}
            &nbsp; | &nbsp; Sitio hecho por
            {' '}
            <a target="_blank" rel="noopener noreferrer" href="https://zeke.cl">
              Zeke
            </a>
            .
          </p>
        </Col>
      </Row>


    </footer>
);

export default Footer;
