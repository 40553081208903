import member from './member';
import recipes from './recipes';
import chartReducer from './chart';
import mapReducer from './map';

const rehydrated = (state = false, action) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return true;
    default:
      return state;
  }
};

export default {
  rehydrated,
  member,
  recipes,
  chartReducer,
  mapReducer,
};
