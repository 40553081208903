const devMode = (process.env.NODE_ENV !== 'development');

export default {
  // App Details
  appName: 'Aire Salud',

  // Build Configuration - eg. Debug or Release?
  // DEV: devMode,
  DEV: false,

  // Google Analytics - uses a 'dev' account while we're testing
  gaTrackingId: 'UA-141466126-1', // prod: 'UA-141466126-1',

  authPassword: 'kVsk5BgwcYbr5NtWKGWqRm25yW2EXmY9dVS6yvLaUK5WpzcHTFU9N7KbbsNh3Jq5zEzAVXYaFucHUZXzC35FJXnFNuT384HtR4rRdcXGBgjyafczHqNyJB9Ad37JNEXc'
};
