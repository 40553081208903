import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import logoAirData from '../../images/logos/logo-airdata.png';
import logoAria from '../../images/logos/logo-aria.png';
import logoCams from '../../images/logos/logo-cams.png';
import logoFundamas from '../../images/logos/logo-fundamas.png';
import logoZeke from '../../images/logos/logo-zeke.png';
import logoCopernicus from '../../images/logos/logo-copernicus.png';

import logoEcmwf from '../../images/logos/logo-ecmwf.png';
import logoEuropean from '../../images/logos/logo-european.png';



const styles = theme => ({

});

const AboutSection = (props) => {
  const { classes } = props;
  return (
    <div className="section">
      <h1>Quienes somos</h1>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={16} direction="row" alignItems="center" justify="center">
            <Grid item xs={12}>
              <Paper className="paper" style={{height: '100%'}}>
                <h4>Auspiciador</h4>
                <hr/>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item>

                    <Grid container
                          spacing={0} direction="row">
                      <Grid item xs={12} sm={6} style={{textAlign: 'center'}}>
                        <a href="https://www.copernicus.eu/en" target='_blank' rel="noopener noreferrer" style={{display: 'inline-block', marginBottom: '20px'}}>
                          <img width={150} src={logoCopernicus} alt="Copernicus"/>
                        </a>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{textAlign: 'center'}}>
                        <a href="https://atmosphere.copernicus.eu/" target='_blank' rel="noopener noreferrer" style={{display: 'inline-block', marginBottom: '20px'}}>
                          <img width={150} src={logoCams} alt="logoCams"/>
                        </a>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{textAlign: 'center'}}>
                        <a href="https://www.ecmwf.int/" target='_blank' rel="noopener noreferrer" style={{display: 'inline-block', marginBottom: '20px'}}>
                          <img width={150} src={logoEcmwf} alt="logoEcmwf"/>
                        </a>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{textAlign: 'center'}}>
                        <a href="https://ec.europa.eu/commission/" target='_blank' rel="noopener noreferrer" style={{display: 'inline-block', marginBottom: '20px'}}>
                          <img width={150} src={logoEuropean} alt="logoEuropean"/>
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className="paper" style={{height: '100%'}}>
                <h4>Equipo Aire Salud</h4>
                <hr/>

                <Grid
                  container
                  spacing={0}
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid className="" item>
                    <a href="http://www.aria.fr" target='_blank' rel="noopener noreferrer">
                      <img width={110} src={logoAria} alt="logoAria" />
                    </a>
                  </Grid>
                </Grid>

                <Grid className=""
                      container
                      spacing={40}
                      direction="row"
                      alignItems="center"
                      justify="center"
                      style={{marginTop: '20px'}}
                >
                  <Grid item>
                    <a href="http://www.airdata.cl" target='_blank' rel="noopener noreferrer">
                      <img width={110} src={logoAirData} alt="AirData" />
                    </a>
                  </Grid>
                  <Grid item>
                    <a href="http://www.polenes.cl" target='_blank' rel="noopener noreferrer">
                      <img width={110} src={logoFundamas} alt="logoFundamas"/>
                    </a>
                  </Grid>
                  <Grid item>
                    <a href="http://www.zeke.cl" target='_blank' rel="noopener noreferrer">

                      <img width={110} src={logoZeke} alt="logoZeke"/>
                    </a>
                  </Grid>
                </Grid>

              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Paper className="paper justifyText" style={{height: '100%'}}>
            <p>
              Somos un equipo interdisciplinario compuesto de epidemiólogos, médicos, meteorólogos y data scientists.
            </p>
            <p>
              El proyecto Aire Salud tiene como objetivo pronosticar el aumento de infecciones respiratorias en base a parámetros epidemiológicos y atmosféricos. En particular, el sistema Aire Salud mide los impactos de la circulación viral y de la contaminación del aire, que aumentan drásticamente durante el invierno.
            </p>
            <p>
              Esta iniciativa nació gracias a un financiamiento del programa de la Unión Europea CAMS (Copernicus Atmospheric Monitoring Services), que busca proveer servicios atmosféricos en todo el mundo en base a observaciones satelitales.
            </p>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(AboutSection);
