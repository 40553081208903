import { Firebase, FirebaseRef } from '../lib/firebase';
import commonConstants from '../constants/commonConstants';


const mapInfeccionesDataCategoryUrlParam = (filters) => {
  if( filters.infeccionesDataCategory === commonConstants.INFECCIONES_DATA_CATEGORY.POR_CUADRO_RESPIRATORIO){
    return "by_disease";
  }

  if( filters.infeccionesDataCategory === commonConstants.INFECCIONES_DATA_CATEGORY.POR_GRUPO_ETARIO){
    return "by_age";
  }
};

const getFirebaseUrlWithFilters = (filters) => {
  const baseUrl = commonConstants.FIREBASE_MAIN_DATA_BASE_URL;
  if (filters.chartType === commonConstants.CHART_TYPES.BY_INFECCIONES_RESPIRATORIAS) {
    let chartTypeUrl = baseUrl + "by_infecciones/";
    if (filters.yearComparatorName.length > 0) {
      return chartTypeUrl + `by_years/${filters.region}/${filters.comuna}`;
    }
    return chartTypeUrl
      + mapInfeccionesDataCategoryUrlParam(filters) + "/"
      + filters.region + "/"
      + filters.comuna;
  }
  if(filters.chartType === commonConstants.CHART_TYPES.BY_VIRUS_RESPIRATORIOS) {
    if(filters.yearComparatorName.length === 0){
      return `${baseUrl}by_virus/${filters.region}`;
    }else{
      return `${baseUrl}by_virus/by_years/${filters.region}/${filters.virus}`;
    }
  }
};

const useLocalData = (filters) => {
  return filters.filterChanged === 'virus' && filters.yearComparatorName.length === 0;
};

export function getChartData(filters) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  filters = filters || commonConstants.DEFAULT_FILTERS;
  // console.log("DEBUG actions/chart.js filters: ", filters);

  if( useLocalData(filters) ) {
    return dispatch => new Promise(resolve => {
      return resolve(dispatch({
        type: 'CHART_LOCAL_DATA',
        filters
      }));
    });
  }else{
    let firebaseUrl = getFirebaseUrlWithFilters(filters);

    return dispatch => new Promise(resolve =>
      FirebaseRef.child(firebaseUrl).on('value', (snapshot) => {
        let chartData = Object.values(snapshot.val() || []) || [];
        return resolve(dispatch({
          type: 'CHART_DATA',
          chartData,
          filters,
        }));
      })).catch(() => {});
  }


}
