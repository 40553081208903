import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LoginModal from './User/LoginModal';
import Member from '../../containers/Member';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});

class Privacy extends React.Component{
  render() {
    const { classes } = this.props;

    return (
      <div className={"justifyText"}>

          <h2>PRIVACIDAD</h2>
          <div className="">
            <div className="ng-binding">
              <h3>Responsable – ¿quién es el responsable del tratamiento de los datos?</h3>

              <strong>Identidad:</strong> ARIA Technologies S.A.<br/>
              <strong>Domicilio social:</strong> 8-10 rue de la Ferme, 92100 Boulogne-Billancourt, France<br/>
              <strong className="ng-binding">SIRET:</strong> 37918047400049<br/>
              <strong>Teléfono:</strong> +32 1 46 08 68 60<br/>
              <strong>Correo Electrónico:</strong> rherve@aria.fr<br/>
              <strong>Contacto:</strong> Robin Hervé<br/>
              <strong>Nombre del dominio:</strong> www.aria.fr<br/>

              <br/>
              <h3>Finalidades – ¿con qué finalidades tratamos tus datos?</h3>

              <p>En cumplimiento de lo dispuesto en el Reglamento Europeo 2016/679 General de Protección de Datos, te
                informamos de que trataremos los datos que nos facilitas para:</p>
              <ul>
                <li>Gestionar la contratación de servicios que realice a través de la Plataforma, así como la
                  facturación y entrega correspondiente.
                </li>
                <li className="ng-binding">Remitir periódicamente comunicaciones sobre servicios, eventos y noticias
                  relacionadas con las actividades desarrolladas por ARIA Technologies S.A., por cualquier medio
                  (teléfono, correo postal o email), salvo que se indique lo contrario o el usuario se oponga o revoque
                  su consentimiento.
                </li>
                <li>Remitir información comercial y / o promocional relacionada con el sector de servicios contratados y
                  valor añadido para usuarios finales, salvo que se indique lo contrario o el usuario se oponga o
                  revoque su consentimiento.
                </li>
                <li>Dar cumplimiento a las obligaciones legalmente establecidas, así como verificar el cumplimiento de
                  las obligaciones contractuales, incluía la prevención de fraude.
                </li>
                <li>Cesión de datos a organismos y autoridades, siempre y cuando sean requeridos de conformidad con las
                  disposiciones legales y reglamentarias.
                </li>
              </ul>


              <h3>Categorías de datos – ¿Qué datos tratamos?</h3>

              <p className="ng-binding">Derivada de las finalidades antes mencionadas, en ARIA Technologies S.A.
                gestionamos las siguientes categorías de datos:</p>
              <ul>
                <li>Datos identificativos</li>
                <li>Metadatos de comunicaciones electrónicas</li>
                <li className="ng-binding">Datos de información comercial. En caso de que el usuario facilite datos de
                  terceros, manifiesta contar con el consentimiento de estos y se compromete a trasladarle la
                  información contenida en esta cláusula, eximiendo a ARIA Technologies S.A. de cualquier
                  responsabilidad en este sentido.
                </li>
                <li className="ng-binding">No obstante, ARIA Technologies S.A. podrá llevar a cabo las verificaciones
                  para constatar este hecho, adoptando las medidas de diligencia debida que correspondan, conforme a la
                  normativa de protección de datos.
                </li>
              </ul>


              <h3>Legitimación – ¿cuál es la legitimación para el tratamiento de tus datos?</h3>

              <p className="ng-binding">El tratamiento de datos cuyo fin es el envío de boletines periódicos
                (newslettering) sobre servicios, eventos y noticias relacionadas con nuestra actividad profesional se
                basa en el consentimiento del interesado, solicitado expresamente para llevar a cabo dichos
                tratamientos, de acuerdo con la normativa vigente.<br/>
                  Además, la legitimación para el tratamiento de los datos relacionados con ofertas o colaboraciones se
                  basan en el consentimiento del usuario que remite sus datos, que puede retirar en cualquier momento,
                  si bien ello puede afectar a la posible comunicación de forma fluida y obstrucción de procesos que
                  desea realizar.<br/>
                  Por último, los datos se podrán utilizar para dar cumplimiento a las obligaciones legales aplicables a
                  ARIA Technologies S.A.
              </p>


              <h3>Plazo de Conservación de los Datos – ¿Por cuánto tiempo conservaremos tus datos?</h3>

              <p className="ng-binding">ARIA Technologies S.A. conservará los datos personales de los usuarios
                únicamente durante el tiempo necesario para la realización de las finalidades para las que fueron
                recogidos, mientras no revoque los consentimientos otorgados. Posteriormente, en caso de ser necesario,
                mantendrá la información bloqueada durante los plazos legalmente establecidos.</p>

              <h3>Destinatarios ¿A qué destinatarios se comunicarán tus datos?</h3>

              <p className="ng-binding">Tus datos podrán ser accedidos por aquellos proveedores que prestan servicios a
                ARIA Technologies S.A., tales como servicios de alojamiento, herramientas de marketing y sistemas de
                contenido u otros profesionales, cuando dicha comunicación sea necesaria normativamente, o para la
                ejecución de los servicios contratados.<br/><br/>
                  ARIA Technologies S.A., ha suscrito los correspondientes contratos de encargo de tratamiento con cada
                  uno de los proveedores que prestan servicios a ARIA Technologies S.A., con el objetivo de garantizar
                  que dichos proveedores tratarán tus datos de conformidad con lo establecido en la legislación vigente.<br/><br/>
                  También podrán ser cedidos a las Fuerzas y Cuerpos de Seguridad del Estado en los casos que exista una
                  obligación legal.<br/><br/>
                  Bancos y entidades financieras, para el cobro de los servicios.<br/>
                  Administraciones públicas con competencia en los sectores de actividad, cuando así lo establezca la
                  normativa vigente.
              </p>

              <h3>
                Seguridad de la Información – ¿Qué medidas de seguridad implantamos para cuidar sus datos?
              </h3>
              <p>
                Para proteger las diferentes tipologías de datos reflejados en esta política de privacidad llevará a
                cabo las medidas de seguridad técnicas necesarias para evitar su pérdida, manipulación, difusión o
                alteración.
              </p>
              <ul>
                <li className="ng-binding">Encriptación de las comunicaciones entre el dispositivo del usuario y los
                  servidores de ARIA Technologies S.A.
                </li>
                <li className="ng-binding">Encriptación de la información en los propios servidores de ARIA Technologies
                  S.A.
                </li>
                <li>Otras medidas que eviten el acceso a los datos del usuario por parte de terceros.</li>
                <li className="ng-binding">En aquellos casos en los que ARIA Technologies S.A. cuente con prestadores de
                  servicio para el mantenimiento de la plataforma que se encuentren fuera de la Unión Europea, estas
                  trasferencias internacionales se hayan regularizadas atendiendo al compromiso de ARIA Technologies
                  S.A. con la protección, integridad y seguridad de los datos personales de los usuarios.
                </li>
              </ul>

              <h3>Derechos – ¿Cuáles son tus derechos cuando nos facilitas tus datos y cómo puedes ejercerlos?</h3>
              <p className="ng-binding">
                Tienes derecho a obtener confirmación sobre si en ARIA Technologies S.A. estamos tratando datos
                personales que te conciernan, o no.<br/>
                Asimismo, tienes derecho a acceder a tus datos personales, así como a solicitar la rectificación de los
                datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean
                necesarios para los fines que fueron recogidos.<br/><br/>
                En determinadas circunstancias, podrás solicitar la limitación del tratamiento de tus datos, en cuyo
                caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.<br/>
                En determinadas circunstancias y por motivos relacionados con tu situación particular, podrás oponerte
                al tratamiento de tus datos. ARIA Technologies S.A. dejará de tratar los datos, salvo por motivos
                legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.<br/><br/>
                Asimismo, puedes ejercer el derecho a la portabilidad de los datos, así como retirar los consentimientos
                facilitados en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el
                consentimiento previo a su retirada.<br/><br/>

                Si deseas hacer uso de cualquiera de tus derechos puede dirigirse a <a href="mailto:rherve@aria.fr"
                                                                                       className="ng-binding">rherve@aria.fr</a>. <br/><br/>
                Por último, te informamos que puedes dirigirte ante la Agencia Española de Protección de Datos y demás
                organismos públicos competentes para cualquier reclamación derivada del tratamiento de tus datos
                personales.

              </p>


              <h3>Modificación de la política de privacidad </h3>


              <p className="ng-binding">
                ARIA Technologies S.A. podrá modificar la presente Política de Privacidad en cualquier momento, siendo
                publicadas las sucesivas versiones en el Sitio Web. En cualquier caso, ARIA Technologies S.A. comunicará
                con previo aviso las modificaciones de la presente política que afecten a los usuarios a fin de que
                puedan aceptar las mismas. <br/><br/>
                La presente Política de Privacidad se encuentra actualizada a fecha 27/12/2018 ARIA Technologies S.A.
                (España). Reservados todos los derechos.
                <br/><br/>
                  Si lo deseas también puedes consultar nuestra <strong><a href="cookies">Política de
                  Cookies</a></strong>

              </p>


            </div>
          </div>

      </div>
    );
  }
}

export default withStyles(styles)(Privacy);







