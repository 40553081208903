/* global window */
import React from 'react';
import { Col, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { goToAnchor } from 'react-scrollable-anchor';

const SidebarNavItems = () => (
  <div>
    <NavItem>
      <Link className={`nav-link ${(window.location.hash === '#pronostico' || window.location.hash === '') && 'active'}`}
            to="/#pronostico"
            onClick={()=>{
              goToAnchor('pronostico')
            }}>
        <i className="icon-graph" />
        {' '}
        <span>Pronóstico</span>
      </Link>
    </NavItem>
    <NavItem>
      {/*<a className={`nav-link ${window.location.hash.startsWith('#map-section') && 'active'}`} href='#map-section'> Mapa de riesgo </a>*/}
      <Link className={`nav-link ${window.location.hash.startsWith('#mapa') && 'active'}`}
            to="/#mapa"
            onClick={()=>{
              goToAnchor('mapa')
            }}>
        <i className="icon-map" />
        {' '}
        <span>Mapa de riesgo</span>
      </Link>
    </NavItem>
    <NavItem data-toggle="collapse"
             data-target=".navbar-collapse">
      <Link className={`nav-link ${window.location.hash.startsWith('#metodologia') && 'active'}`}
            to="/#metodologia"
            onClick={()=>{
              goToAnchor('metodologia')
            }}>
        <i className="icon-graduation" />
        {' '}
        <span>Metodología</span>
      </Link>
    </NavItem>
    <NavItem>
      <Link className={`nav-link ${window.location.hash.startsWith('#about') && 'active'}`}
            to="/#about"
            onClick={()=>{
              goToAnchor('about')
            }}>
        <i className="icon-people" />
        {' '}
        <span>Quienes Somos</span>
      </Link>
    </NavItem>
    <NavItem>
      <Link className={`nav-link ${window.location.hash.startsWith('#contacto') && 'active'}`}
            to="/#contacto"
            onClick={()=>{
              goToAnchor('contacto')
            }}>
        <i className="icon-envelope-open" />
        {' '}
        <span>Contacto</span>
      </Link>
    </NavItem>
  </div>
);

const Sidebar = () => (
  <div>
    <Col id="sidebar-menu" sm="3" md="2" className="d-none d-sm-block sidebar">
      <Nav vertical>
        {SidebarNavItems()}
      </Nav>
    </Col>
  </div>
);

export { Sidebar, SidebarNavItems };
