import Store from '../store/chartData';
import commonConstants from '../constants/commonConstants';

export const initialState = Store;

const createVirusOtrosProperty = (chartDataElement, filters) => {
  chartDataElement.otros = chartDataElement.tot_resp -
    (chartDataElement.VRS +
      chartDataElement.Ad +
      chartDataElement.Para +
      chartDataElement.Inf_A +
      chartDataElement.Inf_B +
      chartDataElement.Meta
    );
  if ( isNaN(chartDataElement.otros) ){
    delete chartDataElement.otros
  }
};

const createOtrosProperty = (chartDataElement, filters) => {
  chartDataElement.otros = chartDataElement.tot_resp -
    (chartDataElement.IRA_Alta +
      chartDataElement.Bronquitis +
      chartDataElement.Neumonia +
      chartDataElement.Tipo_Influenza);

  if ( isNaN(chartDataElement.otros) ){
    delete chartDataElement.otros
  }
};

const addForecastProperties = (chartDataArray, filters, i) => {
  let currentYear = new Date().getFullYear().toString();
  if(filters.yearComparatorName.length > 0 && filters.yearComparatorName.includes(currentYear)){
    if( chartDataArray[i].hasOwnProperty(currentYear) ){
      // console.log("TIENE MISMO AÑO ACTUAL!");
      chartDataArray[i].tot_resp = chartDataArray[i][currentYear];
      delete chartDataArray[i][currentYear];
    }
  }
  if(chartDataArray[i].hasOwnProperty('forecast') && chartDataArray[i].forecast){
    if(i !== 0){
      chartDataArray[i-1].forecastValue = chartDataArray[i-1].tot_resp;
    }
    chartDataArray[i].forecastValue = chartDataArray[i].tot_resp;

    if(!(i+1 < chartDataArray.length && chartDataArray[i+1].hasOwnProperty('forecast') && chartDataArray[i+1].forecast)) {
      delete chartDataArray[i].tot_resp;
    }
    if(i-1 !== 0 && chartDataArray[i-1].hasOwnProperty('forecast') && chartDataArray[i-1].forecast) {
      delete chartDataArray[i-1].tot_resp;
    }

  }
};

const processChartData = (chartData, filters) => {
  for (let i=0; i < chartData.length; i++){
    if(filters.yearComparatorName.length === 0){
      if(filters.infeccionesDataCategory === commonConstants.INFECCIONES_DATA_CATEGORY.POR_CUADRO_RESPIRATORIO){
        createOtrosProperty(chartData[i], filters);
      }
      if(filters.chartType === commonConstants.CHART_TYPES.BY_VIRUS_RESPIRATORIOS){
        createVirusOtrosProperty(chartData[i], filters);
      }
    }
    addForecastProperties(chartData, filters, i);
  }
};

const getTodayRedCirclePoints = (chartData, filters) => {
  if(chartData.length <= 1)
    return false;
  let x = chartData[chartData.length-2].week;
  let y = chartData[chartData.length-2].tot_resp;
  y = y === undefined ? chartData[chartData.length-2].forecastValue : y;
  // console.log("reducers/chart.js -> todayRedCirclePoints", {x, y});
  return {x, y};
};

export default function recipeReducer(state = initialState, action) {
  let filters = action.filters || commonConstants.DEFAULT_FILTERS;
  let todayRedCirclePoints = false;
  switch (action.type) {
    case 'CHART_DATA': {
      // console.log("reducers/chart.js -> CHART_DATA -> action.chartData: ", action.chartData);
      let chartData = [];
      if( action.hasOwnProperty('chartData') ) {
        chartData = action.chartData;
        processChartData(chartData, filters);
      }

      todayRedCirclePoints = getTodayRedCirclePoints(chartData, filters);

      return {
        ...state,
        chartData,
        filters: action.filters,
        useLocalData: false,
        todayRedCirclePoints
      };
    }

    case 'CHART_LOCAL_DATA': {
      // console.log("reducers/chart.js -> CHART_LOCAL_DATA -> state.chartData", state.chartData);
      let chartLocalData = [];
      let todayRedCirclePoints = false;
      if(filters.virus === commonConstants.VIRUS.TOT_RESP) {
        chartLocalData = state.chartData;
      }else{
        // console.log("reducers/chart.js -> CHART_LOCAL_DATA -> cleaning data with VIRUS_TYPE -> ", filters.virus);
        try{
          state.chartData.forEach((item, index) => {
            chartLocalData.push({
              tot_resp: item[filters.virus],
              week: item.week,
            });
            if( item.hasOwnProperty('forecast') && item.forecast ){
              chartLocalData[index].forecast = item.forecast;
            }
          });
          state.chartData.forEach((item, index) => {
            addForecastProperties(chartLocalData, filters, index);
          });
          todayRedCirclePoints = getTodayRedCirclePoints(chartLocalData, filters);
        }catch(e){
        }
        // console.log("reducers/chart.js -> CHART_LOCAL_DATA -> NO VIRUS.TOT_RESP -> AFTER CLEANING chartLocalData: ", chartLocalData);
        // console.log("reducers/chart.js -> CHART_LOCAL_DATA -> NO VIRUS.TOT_RESP -> AFTER CLEANING state.chartData: ", state.chartData);
      }

      return {
        ...state,
        chartLocalData,
        filters: action.filters,
        useLocalData: true,
        todayRedCirclePoints
      };
    }

    default:
      return state;
  }
}
