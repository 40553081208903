// export default {
//   apiKey: "AIzaSyDwJmpy5NI6o0el6iigXo3GHei4qx9pKew",
//   authDomain: "aire-salud.firebaseapp.com",
//   databaseURL: "https://aire-salud.firebaseio.com",
//   projectId: "aire-salud",
//   storageBucket: "aire-salud.appspot.com",
//   messagingSenderId: "768974975493",
//   appId: "1:768974975493:web:55c9bd1188824a5a"
// };

export default {
  apiKey: "AIzaSyB0QxVdQr_W7l9IzppkDdzOjuW0Qh_LLpk",
  authDomain: "aire-salud-ea127.firebaseapp.com",
  databaseURL: "https://aire-salud-ea127.firebaseio.com",
  projectId: "aire-salud-ea127",
  storageBucket: "aire-salud-ea127.appspot.com",
  messagingSenderId: "762337738737",
  appId: "1:762337738737:web:3321f5d94f66bbed"
};
