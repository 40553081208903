export default {

  // filters: {
  //   infeccionesDataCategory: 1,
  //   comuna: 0,
  //   virus: 1,
  //   tabIndex: 0,
  //   yearComparatorName: []
  // },

  chartData: [
    {
      "week": "11",
      "tot_resp": 36000,
      "IRA_Alta": 23000,
      "Bronquitis": 10000,
      "Neumonia": 1500,
      "Tipo_Influenza": 500
    },
    {
      "week": "12",
      "tot_resp": 36000,
      "IRA_Alta": 23000,
      "Bronquitis": 10000,
      "Neumonia": 1500,
      "Tipo_Influenza": 500
    },
    {
      "week": "13",
      "tot_resp": 36000,
      "IRA_Alta": 23000,
      "Bronquitis": 10000,
      "Neumonia": 1500,
      "Tipo_Influenza": 500
    },
    {
      "week": "14",
      "tot_resp": 24000,
      "IRA_Alta": 17000,
      "Bronquitis": 5000,
      "Neumonia": 800,
      "Tipo_Influenza": 300
    },
    {
      "week": "15",
      "forecast": true,
      "tot_resp": 38804,
      "IRA_Alta": 26775,
      "Bronquitis": 6209,
      "Neumonia": 1164,
      "Tipo_Influenza": 388
    }
  ],
};
