import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ReCAPTCHA from "react-google-recaptcha";

import TextField from '../../../node_modules/@material-ui/core/es/TextField/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '../../../node_modules/@material-ui/core/es/Button/Button';
import FormControl from '../../../node_modules/@material-ui/core/FormControl/FormControl';
import InputLabel from '../../../node_modules/@material-ui/core/InputLabel/InputLabel';
import * as ReactDOM from 'react-dom';

import Input from '../../../node_modules/@material-ui/core/Input/Input';
import { Firebase } from '../../lib/firebase';

// const firebase = require("firebase");
require("firebase/functions");

const recaptchaRef = React.createRef();

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing.unit / 4
  }
});


class ChartBox extends React.Component {

  static propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    member: PropTypes.shape({
      firstName: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    loggedIn: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      subject: '',
      message: '',
      recaptchaIsValid: false,
      submitButtonEnable: false
    };
  }



  cleanState = () => {
    this.setState({
      subject: '',
      message: '',
      recaptchaIsValid: false,
      submitButtonEnable: false
    });
    recaptchaRef.current.reset();
  };

  itIsAbleToSubmit = () => {
    const {subject, message, recaptchaIsValid} = this.state;
    return subject!=='' && message!=='' && recaptchaIsValid;
  };

  handleSubjectInputChange = event => {
    this.setState({ subject: event.target.value, submitButtonEnable: this.itIsAbleToSubmit() });
  };

  handleMessageInputChange = event => {
    this.setState({ message: event.target.value, submitButtonEnable: this.itIsAbleToSubmit() });
  };

  handleReCaptchaChange = captchaValue => {
    if(captchaValue !== null) {
      this.setState({ recaptchaIsValid: true, submitButtonEnable: this.itIsAbleToSubmit() });
    }
  };

  handleSubmit = event => {
    const {subject, message } = this.state;
    if( this.itIsAbleToSubmit() ) {
      var sendMail = Firebase.functions().httpsCallable('sendMail');
      var self = this;
      var userEmail = this.props.member.email;
      var userName = this.props.member.firstName;
      console.log("sending contact info from user ", userName);
      console.log("email: ", userEmail);
      sendMail({subject: subject, message: message, userEmail: userEmail, userName: userName}).then(function(result) {
        alert("Mensaje enviado!");
        self.cleanState();
      }).catch(error => {
        //TODO: Siempre sale error por no tener formato correcto. Una vez arreglado eso, se debe cambiar el mensaje a algo que diga "error" para el usuario.
        alert("Mensaje enviado!");
        self.cleanState();
      });
    }

  };

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (

      <div className="section">
        <h1>Contacto</h1>
        <Grid container spacing={16} direction="row" justify="center" alignItems="center">

          <Grid item >
            <Paper className="paper">
              <p>Déjanos un mensaje, te contestaremos a la brevedad.</p>

              <form onSubmit={this.handleSubmit}>

                <FormControl fullWidth>
                  <InputLabel htmlFor="adornment-amount">Asunto</InputLabel>
                  <Input
                    id="adornment-amount"
                    value={this.state.subject}
                    onChange={this.handleSubjectInputChange}
                  />
                </FormControl>
                <br/><br/>
                <FormControl fullWidth>
                  <InputLabel htmlFor="adornment-amount">Mensaje</InputLabel>
                  <Input
                    id="adornment-amount"
                    value={this.state.message}
                    onChange={this.handleMessageInputChange}
                    multiline
                    rows={4}
                    rowsMax={4}
                  />
                </FormControl>

                <br/>
                <br/>

                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LdVz6QUAAAAAIVOcTMLxNTCn-3xoNbPCJ6-fezX"
                  onChange={this.handleReCaptchaChange}
                />
                <br/>

                <Button onClick={this.handleSubmit} variant="outlined" color="primary" className={classes.button} disabled={!this.itIsAbleToSubmit()}>
                  Enviar
                </Button>
              </form>

            </Paper>
          </Grid>

        </Grid>

      </div>



    );
  }

}

export default withStyles(styles)(ChartBox);
