const comunasRm = {
  "Todas": {order: "0", id: "Todas", name: "Todas las Comunas"},
  "Alhue": {order: "1", id: "Alhue", name: "Alhue" },
  "Buin": {order: "2", id: "Buin", name: "Buin" },
  "Calera_de_Tango": {order: "3", id: "Calera_de_Tango", name: "Calera de Tango" },
  "Cerrillos": {order: "4", id: "Cerrillos", name: "Cerrillos" },
  "Cerro_Navia": {order: "5", id: "Cerro_Navia", name: "Cerro Navia" },
  "Colina": {order: "6", id: "Colina", name: "Colina" },
  "Conchali": {order: "7", id: "Conchali", name: "Conchali" },
  "Curacavi": {order: "8", id: "Curacavi", name: "Curacavi" },
  "El_Bosque": {order: "9", id: "El_Bosque", name: "El Bosque" },
  "El_Monte": {order: "10", id: "El_Monte", name: "El Monte" },
  "Estacion_Central": {order: "11", id: "Estacion_Central", name: "Estacion Central" },
  "Huechuraba": {order: "12", id: "Huechuraba", name: "Huechuraba" },
  "Independencia": {order: "13", id: "Independencia", name: "Independencia" },
  "Isla_de_Maipo": {order: "14", id: "Isla_de_Maipo", name: "Isla de Maipo" },
  "La_Cisterna": {order: "15", id: "La_Cisterna", name: "La Cisterna" },
  "La_Florida": {order: "16", id: "La_Florida", name: "La Florida" },
  "La_Granja": {order: "17", id: "La_Granja", name: "La Granja" },
  "La_Pintana": {order: "18", id: "La_Pintana", name: "La Pintana" },
  "La_Reina": {order: "19", id: "La_Reina", name: "La Reina" },
  "Lampa": {order: "20", id: "Lampa", name: "Lampa" },
  "Las_Condes": {order: "21", id: "Las_Condes", name: "Las Condes" },
  "Lo_Barnechea": {order: "22", id: "Lo_Barnechea", name: "Lo Barnechea" },
  "Lo_Espejo": {order: "23", id: "Lo_Espejo", name: "Lo Espejo" },
  "Lo_Prado": {order: "24", id: "Lo_Prado", name: "Lo Prado" },
  "Macul": {order: "25", id: "Macul", name: "Macul" },
  "Maipu": {order: "26", id: "Maipu", name: "Maipu" },
  "Maria_Pinto": {order: "27", id: "Maria_Pinto", name: "Maria Pinto" },
  "Melipilla": {order: "28", id: "Melipilla", name: "Melipilla" },
  "Nunoa": {order: "29", id: "Nunoa", name: "Nunoa" },
  "Padre_Hurtado": {order: "30", id: "Padre_Hurtado", name: "Padre Hurtado" },
  "Paine": {order: "31", id: "Paine", name: "Paine" },
  "Pedro_Aguirre_Cerda": {order: "32", id: "Pedro_Aguirre_Cerda", name: "Pedro Aguirre Cerda" },
  "Penaflor": {order: "33", id: "Penaflor", name: "Penaflor" },
  "Penalolen": {order: "34", id: "Penalolen", name: "Penalolen" },
  "Pirque": {order: "35", id: "Pirque", name: "Pirque" },
  "Providencia": {order: "36", id: "Providencia", name: "Providencia" },
  "Pudahuel": {order: "37", id: "Pudahuel", name: "Pudahuel" },
  "Puente_Alto": {order: "38", id: "Puente_Alto", name: "Puente Alto" },
  "Quilicura": {order: "39", id: "Quilicura", name: "Quilicura" },
  "Quinta_Normal": {order: "40", id: "Quinta_Normal", name: "Quinta Normal" },
  "Recoleta": {order: "41", id: "Recoleta", name: "Recoleta" },
  "Renca": {order: "42", id: "Renca", name: "Renca" },
  "San_Bernardo": {order: "43", id: "San_Bernardo", name: "San Bernardo" },
  "San_Joaquin": {order: "44", id: "San_Joaquin", name: "San Joaquin" },
  "San_Jose_de_Maipo": {order: "45", id: "San_Jose_de_Maipo", name: "San Jose de Maipo" },
  "San_Miguel": {order: "46", id: "San_Miguel", name: "San Miguel" },
  "San_Pedro": {order: "47", id: "San_Pedro", name: "San Pedro" },
  "San_Ramon": {order: "48", id: "San_Ramon", name: "San Ramon" },
  "Santiago": {order: "49", id: "Santiago", name: "Santiago" },
  "Talagante": {order: "50", id: "Talagante", name: "Talagante" },
  "Tiltil": {order: "51", id: "Tiltil", name: "Tiltil" },
  "Vitacura": {order: "52", id: "Vitacura", name: "Vitacura" }
};

export default comunasRm;
