import React from 'react';
import comunasRm from './comunasRm';
import utils from '../helpers/utils';

const commonConstants = {
  CHART_TYPES: {
    BY_INFECCIONES_RESPIRATORIAS: 1,
    BY_VIRUS_RESPIRATORIOS: 0
  },
  INFECCIONES_DATA_CATEGORY: {
    POR_CUADRO_RESPIRATORIO: 0,
    POR_GRUPO_ETARIO: 1
  },
  VIRUS: {
    TOT_RESP: 'tot_resp',
    VRS: 'VRS',
    AD: 'Ad',
    PARA: 'Para',
    INF_A: 'Inf_A',
    INF_B: 'Inf_B',
    META: 'Meta'
  },
  TODAS_LAS_COMUNAS_ID: "Todas",
  DEFAULT_FILTERS: {
    infeccionesDataCategory: 0,
    region: "RM",
    comuna: comunasRm['Todas'].id,
    virus: 'tot_resp',
    chartType: 0,
    yearComparatorName: [],
    filterChanged: undefined,
    isModalOpen: false,
    tooltipDialogOpen: false,
    tooltipDialogContent: "",
  },
  WEEK_PROPERTY: 'week',
  HEAT_MAP_MAPPING: {
    "low": {name:"Bajo", className: "svg-map__location--heat-low"},
    "medium": {name:"Medio", className: "svg-map__location--heat-medium"},
    "high": {name:"Alto", className: "svg-map__location--heat-high"},
    "very high": {name:"Muy Alto", className: "svg-map__location--heat-very-high"},
    "pandemic": {name:"Brote", className: "svg-map__location--heat-pandemic"},
    "no-data": {name: "Sin datos", className: "svg-map__location--heat-no-data" }
  },
  FIREBASE_MAIN_DATA_BASE_URL: 'main_data/',
  dateFormatOptions: {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  },
  getDatesFromWeek: (week) => {
    // let startWeekDate = utils.getDateOfISOWeek(week);
    // let endWeekDate = utils.formatDate(utils.addDays(startWeekDate, 6));
    // startWeekDate = utils.formatDate(startWeekDate);
    // return `${startWeekDate} - ${endWeekDate}`;
    let startWeekDate = utils.getDateOfISOWeek(week);
    const endWeekDate = utils.addDays(startWeekDate, 6);
    return utils.formatDateRange(startWeekDate, endWeekDate);
  },
  chartTooltipPayloadMapping: {
    VRS: {
      name: "VRS",
      color: "#82ca9d",
      fill: "#82ca9d",
    },
    Ad: {
      name: "Adenovirus",
      color: "#413ea0",
      fill: "#413ea0",
    },
    Inf_A: {
      name: "Influenza A",
      color: "#A143A7",
      fill: "#A143A7",
    },
    Inf_B: {
      name: "Influenza B",
      color: "orange",
      fill: "orange",
    },
    Para: {
      name: "Parainfluenza",
      color: "gray",
      fill: "gray",
    },
    Meta: {
      name: "Metapneumovirus",
      color: "#983232",
      fill: "#983232",
    },
    tot_resp: {
      name: "Datos ",
      color: "black",
      fill: "black",
    },
    forecastValue: {
      name: "Pronóstico AIRE SALUD",
      color: "blue",
      fill: "blue",
    },
    otros: {
      name: "Otros",
      color: "gray",
      fill: "gray"
    },
    Bronquitis: {
      name: "Bronquitis",
      color: "#413ea0",
      fill: "#413ea0"
    },
    IRA_Alta: {
      name: "IRA Alta",
      color: "#82ca9d",
      fill: "#82ca9d"
    },
    Neumonia: {
      name: "Neumonía",
      color: "#A143A7",
      fill: "#A143A7"
    },
    Tipo_Influenza: {
      name: "COB",
      color: "orange",
      fill: "orange"
    },

    1: {
      name: "<1 año",
      color: "#82ca9d",
      fill: "#82ca9d"
    },
    2: {
      name: "1-4 años",
      color: "#413ea0",
      fill: "#413ea0"
    },
    3: {
      name: "5-14 años",
      color: "#A143A7",
      fill: "#A143A7"
    },
    4: {
      name: "15-64 años",
      color: "orange",
      fill: "orange"
    },
    5: {
      name: ">65 años",
      color: "gray",
      fill: "gray"
    },

    2011: {
      name: "2011",
      fill: "#9C27B0",
      color: "#9C27B0",
    },
    2012: {
      name: "2012",
      fill: "#00BCD4",
      color: "#00BCD4",
    },
    2013: {
      name: "2013",
      fill: "#F44336",
      color: "#F44336",
    },
    2014: {
      name: "2014",
      fill: "#ff00f1",
      color: "#ff00f1",
    },
    2015: {
      name: "2015",
      fill: "#8BC34A",
      color: "#8BC34A",
    },
    2016: {
      name: "2016",
      fill: "#FFEB3B",
      color: "#FFEB3B",
    },
    2017: {
      name: "2017",
      fill: "#3F51B5",
      color: "#3F51B5",
    },
    2018: {
      name: "2018",
      fill: "#FF9800",
      color: "#FF9800",
    },
    2019: {
      name: "2019",
      fill: "#795548",
      color: "#795548",
    },
    2020: {
      name: "2020",
      fill: "#9E9E9E",
      color: "#9E9E9E",
    },
    2021: {
      name: "2021",
      fill: "#607D8B",
      color: "#607D8B",
    },

  },
  getYearColor: (i) => {
    return [
      "#9C27B0",
      "#00BCD4",
      "#F44336",
      "#ff00f1",
      "#8BC34A",
      "#FFEB3B",
      "#3F51B5",
      "#FF9800",
      "#795548",
      "#9E9E9E",
      "#607D8B",

    ][i];
  },
};

export default commonConstants;
