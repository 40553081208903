import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing.unit / 4
  }
});


// function TabContainer({ children, dir }) {
//   return (
//     <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
//       {children}
//     </Typography>
//   );
// }
// TabContainer.propTypes = {
//   children: PropTypes.node.isRequired,
//   dir: PropTypes.string.isRequired,
// };


// comparador
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const yearComparatorNameList = [
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
];

class ChartBox extends React.Component {

  static propTypes = {
    // chartData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fetchChartData: PropTypes.func.isRequired,
  };

  state = {
    yearComparatorName: []
  };

  handleYearComparatorChange = event => {
    this.setState({ yearComparatorName: event.target.value }, () => {
      // console.log("DEBUG components/ChartYearCompareSelect.js -> calling to fetchChartData...");
      // console.log("yearComparatorName: ", this.state.yearComparatorName);
      const { fetchChartData } = this.props;
      fetchChartData(this.state)
        .then((data) => {})
        .catch((err) => {});
    });
  };

  render() {
    const { classes } = this.props;
    const { yearComparatorName } = this.state;

    return (
      <Grid container alignItems="flex-start" justify="flex-end" direction="row">
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-multiple-chip">Comparar años</InputLabel>
          <Select
            multiple
            value={yearComparatorName}
            onChange={this.handleYearComparatorChange}
            input={<Input id="select-multiple-chip" />}
            style={{minWidth: 150}}
            renderValue={selected => (
              <div className={classes.chips}>
                {selected.map(value => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {yearComparatorNameList.map(name => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={yearComparatorName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
          {
            yearComparatorName.length !== 0 &&
            <Button variant="contained" color="primary">Dejar de comparar</Button>
          }
        </FormControl>
      </Grid>
    );
  }

}

export default withStyles(styles)(ChartBox);
