import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';



const styles = theme => ({

});

class Map extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <Paper className="paper justifyText" style={{height: '100%'}}>
        <strong>Explicación</strong>
        <p>El objetivo de este mapa es exhibir el riesgo de saturación de la red asistencial por aumento de infecciones respiratorias, en cada comuna de la Región Metropolitana.</p>
        <p></p>
        <p>Para cada comuna, se calcula un índice de riesgo que varía entre 5 niveles: bajo, medio, alto, muy alto, brote. </p>
        <p></p>
        <strong>¿Cómo se calcula el índice de riesgo?</strong>
        <p>Los datos históricos (2011-2018) de consultas en urgencias públicas de la comuna son divididos en 5 cuantiles: los cuantiles ‘bajo’ y ‘medio’ corresponden a la demanda en meses de verano, los cuantiles ‘alto’ y ‘muy alto’ corresponden a la demanda en meses de invierno, y el cuantil ‘brote’ corresponde a un récord de demanda histórico para la comuna (nunca superado en años previos).</p>
      </Paper>
    );
  }

}

export default withStyles(styles)(Map);
