import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Nav,
  Navbar,
  Collapse,
  DropdownMenu,
  DropdownItem,
  NavbarToggler,
  DropdownToggle,
  UncontrolledDropdown, Col, NavItem,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import Config from '../../../constants/config';
import { SidebarNavItems } from './Sidebar';
import { goToAnchor } from 'react-scrollable-anchor';
// import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Grid from '@material-ui/core/Grid';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import Typography from '@material-ui/core/Typography';

// function TabContainer({ children, dir }) {
//   return (
//     <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
//       {children}
//     </Typography>
//   );
// }
//
// TabContainer.propTypes = {
//   children: PropTypes.node.isRequired,
//   dir: PropTypes.string.isRequired,
// };


class Header extends Component {
  static propTypes = {
    member: PropTypes.shape({
      firstName: PropTypes.string,
      email: PropTypes.string,
    }),
    logout: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    member: {},
  };

  constructor(props) {
    super(props);

    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.state = {
      isOpen: false,
      tabValueIndex: 0,
      showHeaderTitle: true
    };
  }

  onLogout = () => {
    const { logout, history } = this.props;
    logout().then(() => history.push('/'));
  };

  toggleDropDown = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  handleTabChange = (event, tabValueIndex) => {
    this.setState({ tabValueIndex });
  };

  handleSelectChange = event => {
    // setState({ [event.target.name]: event.target.value });
  };

  getAppTitleStye(){
    let useBigTitle = window.innerWidth > 789;
    return {
      color: '#FFF',
      fontSize: useBigTitle ? '15pt' : '12pt',
      textTransform: 'uppercase',
      letterSpacing: useBigTitle ? '6px' : '2px'
    };
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizeHeader.bind(this));
    this.resizeHeader();
  }

  resizeHeader() {
    this.setState({showHeaderTitle: window.innerWidth > 680});
  }



  render() {
    const { member } = this.props;
    const { isOpen, tabValueIndex, showHeaderTitle } = this.state;
    const loggedIn = !!(member && member.email);
    const {pathname} = this.props.location;


    return (
      <header>
        <Navbar id="main-navbar" dark color="primary" expand="sm" className={'fixed-top'} >

          <Col sm="3">
            <Link to="/" className="navbar-brand" style={{ color: '#FFF' }}>
              {Config.appName}
            </Link>
          </Col>

          {/*<Col sm="6" xs="12">*/}
            {/*<p to="#" className="navbar-brand" style={{color: '#FFF', fontSize: '15pt'}}>*/}
              {/*Pronóstico de infecciones respiratorias*/}
            {/*</p>*/}
          {/*</Col>*/}

          {showHeaderTitle &&
            <Link to="/" style={this.getAppTitleStye()}>
              Pronóstico de infecciones respiratorias
            </Link>
          }



          <NavbarToggler onClick={this.toggleDropDown} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>

              <div className="d-block d-sm-none">
                {/*{SidebarNavItems()}*/}
                <div>
                  <NavItem>
                    <Link className={`nav-link ${(window.location.hash === '#pronostico' || window.location.hash === '') && 'active'}`}
                          to="/#pronostico"
                          onClick={()=>{
                            goToAnchor('pronostico');
                            this.setState({isOpen: false});
                          }}>
                      <i className="icon-graph" />
                      {' '}
                      <span>Pronóstico</span>
                    </Link>
                  </NavItem>
                  <NavItem>
                    {/*<a className={`nav-link ${window.location.hash.startsWith('#map-section') && 'active'}`} href='#map-section'> Mapa de riesgo </a>*/}
                    <Link className={`nav-link ${window.location.hash.startsWith('#mapa') && 'active'}`}
                          to="/#mapa"
                          onClick={()=>{
                            goToAnchor('mapa');
                            this.setState({isOpen: false});
                          }}>
                      <i className="icon-map" />
                      {' '}
                      <span>Mapa de riesgo</span>
                    </Link>
                  </NavItem>
                  <NavItem data-toggle="collapse"
                           data-target=".navbar-collapse">
                    <Link className={`nav-link ${window.location.hash.startsWith('#metodologia') && 'active'}`}
                          to="/#metodologia"
                          onClick={()=>{
                            goToAnchor('metodologia');
                            this.setState({isOpen: false});
                          }}>
                      <i className="icon-graduation" />
                      {' '}
                      <span>Metodología</span>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link className={`nav-link ${window.location.hash.startsWith('#about') && 'active'}`}
                          to="/#about"
                          onClick={()=>{
                            goToAnchor('about');
                            this.setState({isOpen: false});
                          }}>
                      <i className="icon-people" />
                      {' '}
                      <span>Quienes Somos</span>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link className={`nav-link ${window.location.hash.startsWith('#contacto') && 'active'}`}
                          to="/#contacto"
                          onClick={()=>{
                            goToAnchor('contacto');
                            this.setState({isOpen: false});
                          }}>
                      <i className="icon-envelope-open" />
                      {' '}
                      <span>Contacto</span>
                    </Link>
                  </NavItem>
                </div>
              </div>
              {loggedIn ? (
              <UncontrolledDropdown nav>
                <DropdownToggle nav caret>
                  {loggedIn ? `Hola, ${member.firstName}` : 'Perfil'}
                </DropdownToggle>
                <DropdownMenu>
                  {!loggedIn
                  && (
                    <div>
                      <DropdownItem tag={Link} to="/login">
                        Iniciar sesión
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/sign-up">
                        Registrarse
                      </DropdownItem>
                    </div>
                  )
                  }
                  {loggedIn
                  && (
                    <div>
                      <DropdownItem tag={Link} to="/update-profile">
                        Editar Perfil
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem tag="button" onClick={this.onLogout}>
                        Cerrar sesión
                      </DropdownItem>
                    </div>
                  )
                  }
                </DropdownMenu>
              </UncontrolledDropdown> ) : '' }
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}

export default withRouter(Header);
