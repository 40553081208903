import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getChartData } from '../actions/chart';
import commonConstants from '../constants/commonConstants';

class ChartContainer extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    chartData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    chartLocalData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    useLocalData: PropTypes.bool.isRequired,
    fetchChartData: PropTypes.func.isRequired,
    filters: PropTypes.shape(),
    todayRedCirclePoints: PropTypes.shape(),
  };

  static defaultProps = {
    // filters: commonConstants.DEFAULT_FILTERS
  };

  state = {
    error: null,
    loading: false,
  };

  componentDidMount = () => this.fetchChartData();

  fetchChartData = (data) => {
    const { fetchChartData } = this.props;
    this.setState({ loading: true });
    return fetchChartData(data)
      .then((resp) => {
        // console.log("DEBUG ChartContainer.js -> fetchChartData: ", resp);
        this.setState({
          loading: false,
          error: null,
        });
      }).catch(err => this.setState({
        loading: false,
        error: err,
      }));
  };

  render = () => {
    const { Layout, chartData, filters, useLocalData, chartLocalData, todayRedCirclePoints } = this.props;
    const { loading, error } = this.state;
    let layoutChartData;
    if( filters.chartType === commonConstants.CHART_TYPES.BY_VIRUS_RESPIRATORIOS &&
      filters.virus !== commonConstants.VIRUS.TOT_RESP){
        //TODO: SEGUIR CON ESTE CÓDIGO DE VIRUS
    }

    return (
      <Layout
        error={error}
        loading={loading}
        chartData={useLocalData ? chartLocalData : chartData}
        filters={filters}
        todayRedCirclePoints={todayRedCirclePoints}
        fetchChartData={this.fetchChartData}
      />
    );
  }
}

const mapStateToProps = state => ({
  chartData: state.chartReducer.chartData || [],
  chartLocalData: state.chartReducer.chartLocalData || [],
  useLocalData: state.chartReducer.useLocalData || false,
  filters: state.chartReducer.filters || commonConstants.DEFAULT_FILTERS,
  todayRedCirclePoints: state.chartReducer.todayRedCirclePoints || false,
});

const mapDispatchToProps = {
  fetchChartData: getChartData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartContainer);
