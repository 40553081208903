import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Chart from './Chart';
import ChartTypes from './ChartTypes';
import ChartYearCompareSelect from './ChartYearCompareSelect';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ChartContainer from '../../../containers/ChartContainer';

const styles = theme => ({

});

class ChartSection extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Paper className="paper" style={{paddingBottom: '30px'}}>
            <ChartContainer Layout={ChartTypes}/>
            <Chart {...this.props} />
          </Paper>
        </Grid>
      </Grid>

    );
  }

}

export default withStyles(styles)(ChartSection);
