import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import Map from './Map';
import MapText from './MapText';
import MapContainer from '../../../containers/MapContainer';


const styles = theme => ({

});

class MapSection extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <div className="section">
        <h1>Mapa de Riesgo</h1>

        <Grid container spacing={16}>
          <Grid item xs={12} sm={12} md={6}>
            <MapContainer Layout={Map}/>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <MapText/>
          </Grid>
        </Grid>
      </div>
    );
  }

}

export default withStyles(styles)(MapSection);
