import { Firebase, FirebaseRef } from '../lib/firebase';
import commonConstants from '../constants/commonConstants';


// const mapInfeccionesDataCategoryUrlParam = (filters) => {
//   if( filters.infeccionesDataCategory === commonConstants.INFECCIONES_DATA_CATEGORY.POR_CUADRO_RESPIRATORIO){
//     return "by_disease";
//   }
//
//   if( filters.infeccionesDataCategory === commonConstants.INFECCIONES_DATA_CATEGORY.POR_GRUPO_ETARIO){
//     return "by_age";
//   }
// };
//
// const getFirebaseUrlWithFilters = (filters) => {
//   if (filters.chartType === commonConstants.CHART_TYPES.BY_INFECCIONES_RESPIRATORIAS) {
//     let chartTypeUrl = "by_infecciones/";
//     if (filters.yearComparatorName.length > 0) {
//       return chartTypeUrl + `by_years/${filters.region}/${filters.comuna}`;
//     }
//     return chartTypeUrl
//       + mapInfeccionesDataCategoryUrlParam(filters) + "/"
//       + filters.region + "/"
//       + filters.comuna;
//   }
//   if(filters.chartType === commonConstants.CHART_TYPES.BY_VIRUS_RESPIRATORIOS) {
//     if(filters.yearComparatorName.length === 0){
//       return `by_virus/${filters.region}`;
//     }else{
//       return `by_virus/by_years/${filters.region}/${filters.virus}`;
//     }
//   }
// };
//
// const useLocalData = (filters) => {
//   return filters.filterChanged === 'virus' && filters.yearComparatorName.length === 0;
// };

export function getMapData(filters) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  let firebaseUrl = commonConstants.FIREBASE_MAIN_DATA_BASE_URL + "map/RM";
  // console.log("DEBUG actions/map.js Firebase URL: ", firebaseUrl);
  return dispatch => new Promise(resolve => FirebaseRef.child(firebaseUrl)
    .on('value', (snapshot) => {
      // console.log("DEBUG actions/map.js  Firebase data received, snapshot: ", snapshot);
      let heatMapData = snapshot.val() || [];
      // console.log("DEBUG actions/map.js  Firebase heatMapData: ", heatMapData);
      return resolve(dispatch({
        type: 'MAP_DATA',
        heatMapData,
      }));
    })).catch(e => console.log(e));

  // if( useLocalData(filters) ) {
  //   return dispatch => new Promise(resolve => {
  //     return resolve(dispatch({
  //       type: 'CHART_LOCAL_DATA',
  //       filters
  //     }));
  //   });
  // }else{
  //
  // }


}
